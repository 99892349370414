import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { lazy, Suspense } from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"
import Loader from "./component/Loader"

const App = lazy(() => import("./App"))

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
