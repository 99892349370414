import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Box from "@material-ui/core/Box"

export default function CircularProgressBox(args) {
  const { progressProps, ...others } = args
  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...others}>
      <CircularProgress color="primary" m={2} {...progressProps} />
    </Box>
  )
}
