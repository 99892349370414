import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Box from "@material-ui/core/Box"
import CircularProgressBox from "./CircularProgressBox"

const useStyles = makeStyles({
  colorPrimary: {
    color: "#3f4752"
  }
})

export default function Loader() {
  const classes = useStyles()
  return (
    <Box height="100vh" width="100%">
      <CircularProgressBox
        height="100%"
        progressProps={{ className: classes.colorPrimary }}
      />
    </Box>
  )
}
